.container {
  display: flex;
  align-items: center;

  animation: appearWithDelay 1s ease-in;

  cursor: pointer;

  span {
    margin-bottom: -0.5rem;
    margin-left: 0.5rem;
  }

  &:hover {
    span {
      color: var(--color-text-100)
    }
  }
}

.avatars {
  display: flex;
  align-items: center;
  
  // prevents layout shifts during animation and image loading
  width: 66px;
  height: 28px;

  --blured-join-avatar: grayscale(100%) blur(3px) drop-shadow(0 1px 5px var(--color-dark-900));
  --normal-join-avatar: grayscale(100%) blur(0) drop-shadow(0 1px 5px var(--color-dark-900));
}

.image {
  filter: var(--normal-join-avatar);
  border-radius: 50%;
  overflow: hidden;
  animation-delay: calc((var(--avatar-index) + 1) * 0.1s);
  margin-left: -6px;
  transition: all 0.3s;
}

@keyframes appearWithDelay {
  0% {
    opacity: 0;
    transform: translateY(0.5rem);
  }

  50% {
    opacity: 0;
    transform: translateY(0.5rem);
  }

  70% {
    opacity: 1;
    transform: translateY(0);
  }
}

.itemExit, .itemExitActive, .itemExitDone {
  transform: translateY(-0.3rem);
  opacity: 0;
  filter: var(--blured-join-avatar);
}


.itemExitDone {
  display: none;
}

.itemEnter {
  position: absolute;
  opacity: 0;
}

.itemEnterActive {
  transform: translateY(-0.3rem);
  filter: var(--blured-join-avatar);
}

.itemEnterDone {
  transform: translateY(0);
  opacity: 1;
  filter: var(--normal-join-avatar);
}