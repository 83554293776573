@import '../../../screen.scss';

.testimonials {
  display: flex;
  flex-direction: column;

  align-items: center;

  width: 100%;
  height: 100vh;

  margin: var(--margin-1000) 0;

  :global {
    .react-tweet-theme {
      --tweet-container-margin: 0;
      --tweet-body-font-size: 1rem;
      --tweet-bg-color: var(--color-dark-450);
    }
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); // fix https://stackoverflow.com/questions/47601564/equal-width-columns-in-css-grid
  width: 100%;
  min-height: 70vw;
  margin-top: var(--margin-400);
  gap: var(--margin-400);
  justify-content: center;

  min-height: 0;
  flex: 1;

  position: relative;
  overflow: hidden;
  mask-image: linear-gradient(to top, hsl(0 0% 0% / 0), hsl(0 0% 0% / 1) 20%, hsl(0 0% 0% / 1) 80%, hsl(0 0% 0% / 0));
}

.ribbonContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  justify-content: center;

  > * {
    grid-area: 1 / 1;
  }

  .ribbon,
  .ribbonMirror {
    padding-bottom: var(--margin-400);
    padding-top: 0;
    margin-bottom: 0;
    margin-top: 0;

    display: flex;
    flex-direction: column;
    gap: var(--margin-400);
    transform: translateY(100%);
    animation: scroll-vertical 40s linear infinite;
    position: absolute;
  }

  .ribbon {
    animation-delay: -20s;
  }

  .ribbonMirror {
    animation-delay: 0s;
  }
}

.ribbonContainer:nth-child(even) {
  .ribbon,
  .ribbonMirror {
    transform: translateY(-100%);
    animation-direction: reverse;
  }
}

.tweetContainer {
  max-width: var(--width-500);
}

@media (max-width: $screen-desktop) { 
  .content {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .ribbonContainer:nth-child(3) {
    display: none;
  }
}


@media (max-width: $screen-tablet) {
  .testimonials {
    height: auto;
    margin: var(--margin-800);
  }

  .content {
    display: flex;
    flex-direction: column;
    mask-image: linear-gradient(
      to left,
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 20%,
      hsl(0 0% 0% / 1) 80%,
      hsl(0 0% 0% / 0)
    );
  }

  .ribbonContainer {
    flex: none;
    display: grid;
  
    .ribbon {
      padding: 0 var(--margin-400);
    }

    .ribbon,
    .ribbonMirror {
      flex-direction: row;
      transform: translateX(100%);
      animation-name: scroll-horizontal;
      position: relative;
    }
  }

  .ribbonContainer:nth-child(even) {
    .ribbon,
    .ribbonMirror {
      transform: translateX(-100%);
      animation-direction: reverse;
    }
  }

  .ribbonContainer:nth-child(3) {
    display: grid;
  }

  .tweetContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes scroll-horizontal {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll-vertical {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}
